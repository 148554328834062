import * as React from 'react'

import { css } from '#styled-system/css'

export interface InputProps
	extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
	({ className, type, ...props }, ref) => {
		return (
			<input
				type={type}
				className={css({
					width: '100%',
					borderRadius: '4px',
					borderWidth: '1px',
					borderColor: 'gray7',
					backgroundColor: 'gray1',
					paddingInline: '8px',
					paddingBlock: '4px',
					fontSize: '18px',
					color: 'gray11',
					_hover: {
						borderColor: 'gray8',
					},
				})}
				ref={ref}
				{...props}
			/>
		)
	},
)
Input.displayName = 'Input'

export { Input }
