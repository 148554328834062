import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import * as React from 'react'

import { css } from '#styled-system/css'

export type CheckboxProps = Omit<
	React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>,
	'type'
> & {
	type?: string
}

const Checkbox = React.forwardRef<
	React.ElementRef<typeof CheckboxPrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
	<CheckboxPrimitive.Root
		ref={ref}
		className={css({
			height: '16px',
			width: '16px',
			borderRadius: '4px',
			borderWidth: '1px',
			borderColor: 'gray7',
			backgroundColor: 'gray3',
			color: 'gray11',
			flexShrink: 0,
		})}
		{...props}
	>
		<CheckboxPrimitive.Indicator
			className={css({
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				color: 'gray11',
			})}
		>
			<svg
				viewBox="0 0 8 8"
				className={css({
					height: '16px',
					width: '16px',
				})}
			>
				<path
					d="M1,4 L3,6 L7,2"
					stroke="currentcolor"
					strokeWidth="1"
					fill="none"
				/>
			</svg>
		</CheckboxPrimitive.Indicator>
	</CheckboxPrimitive.Root>
))
Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
