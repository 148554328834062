import * as LabelPrimitive from '@radix-ui/react-label'
import * as React from 'react'

import { css } from '#styled-system/css'
const Label = React.forwardRef<
	React.ElementRef<typeof LabelPrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>
>(({ className, ...props }, ref) => (
	<LabelPrimitive.Root
		ref={ref}
		className={css({
			display: 'block',
			fontSize: '14px',
			lineHeight: '20px',
			color: 'gray11',
		})}
		{...props}
	/>
))
Label.displayName = LabelPrimitive.Root.displayName

export { Label }
